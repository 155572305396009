const PERMISSIONS = 'Admin-Perm';
const CARD_PERMISSIONS = "Admin-Card-Perm";

export const setPermissions = (value) =>
  localStorage.setItem(PERMISSIONS, value);
export const getPermissions = () => localStorage.getItem(PERMISSIONS);
export const removePermissions = () => localStorage.removeItem(PERMISSIONS);

export const setCardPermissions = (value) =>
  localStorage.setItem(CARD_PERMISSIONS, value);
export const getCardPermissions = () => localStorage.getItem(CARD_PERMISSIONS);
export const removeCardPermissions = () =>
  localStorage.removeItem(CARD_PERMISSIONS);
