import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import mainLoader from './views/base/mainLoader';
import './scss/style.scss';
import { AuthContext } from './context/auth'
import { getToken } from './helpers/token'
import IdleTimerContainer from "./helpers/idleTimer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const CreatePass = React.lazy(() => import("./views/pages/password/Password"));
const PhoneLogin = React.lazy(() =>
  import("./views/pages/phoneLogin/PhoneLogin")
);
const PasswordReset = React.lazy(() =>
  import("./views/pages/passwordReset/PasswordReset")
);
const PasswordRemind = React.lazy(() =>
  import("./views/pages/passwordRemind/PasswordRemind")
);

class App extends Component {
  render() {
    return (
      <AuthContext.Provider value={getToken()}>
        <IdleTimerContainer></IdleTimerContainer>
        <HashRouter>
          <React.Suspense fallback={mainLoader}>
            <Switch>
              <Route
                exact
                path="/create-password"
                name="create password"
                render={(props) => <CreatePass {...props} />}
              />
              <Route
                exact
                path="/remind-password"
                name="remind password"
                render={(props) => <PasswordRemind {...props} />}
              />
              <Route
                exact
                path="/reset-password/:id"
                name="reset password"
                render={(props) => <PasswordReset {...props} />}
              />
              <Route
                exact
                path="/login"
                name="login"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/phoneLogin"
                name="phoneLogin"
                render={(props) => <PhoneLogin {...props} />}
              />
              <Route
                exact
                path="/register"
                name="register"
                render={(props) =>
                  getToken() !== null ? (
                    <Register {...props} />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
            <ToastContainer
              position={toast.POSITION.TOP_CENTER}
              autoClose={15000}
              pauseOnFocusLoss={false}
              pauseOnHover={false}
              style={{ marginTop: "2%" }}
            />
          </React.Suspense>
        </HashRouter>
      </AuthContext.Provider>
    );
  }
}

export default App;
