import React from 'react';
import { CircleLoader } from 'react-spinners';

import './styles.scss';

const mainLoader = (
  <div className="spinner-outer" style={{ height: 'auto', marginTop: 200 }}>
    <CircleLoader
      size={120}
      color="#4A4A4A"
      loading={true}
    />
  </div>
);

export default mainLoader;