const TOKEN = 'X-AuthToken';
const LIM = "X-Password-Expiration";
const RESET_PASSWORD = "X-Password-Email-reset";

export const setToken = (value) => localStorage.setItem(TOKEN, value);
export const getToken = () => localStorage.getItem(TOKEN);
export const removeToken = () => localStorage.removeItem(TOKEN);

export const sessionSetToken = (value) =>
  window.sessionStorage.setItem(TOKEN, value);
export const sessionGetToken = () => window.sessionStorage.getItem(TOKEN);
export const sessionRemoveToken = () => window.sessionStorage.removeItem(TOKEN);

export const setLimit = (value) => localStorage.setItem(LIM, value);
export const getLimit = () => localStorage.getItem(LIM);
export const removeLimit = () => localStorage.removeItem(LIM);

export const setResetEmail = (value) =>
  localStorage.setItem(RESET_PASSWORD, value);
export const getResetEmail = () => localStorage.getItem(RESET_PASSWORD);
export const removeResetEmail = () => localStorage.removeItem(RESET_PASSWORD);


